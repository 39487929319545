<template>
  <div v-loading="loading" class="area-detail">
    <el-row type="flex" align="middle" justify="space-between" class="title">
      <h3 class="m-0">
        THÔNG TIN CHI TIẾT KHU VỰC {{ area.name.toUpperCase() }}
      </h3>
      <el-button
        v-if="area"
        size="small"
        type="primary"
        icon="el-icon-edit"
        @click="$router.push({ name: 'AreaInfo', params: { id: area.id } })"
      >Chỉnh sửa</el-button>
    </el-row>
    <el-row class="area-tabs">
      <el-tabs v-model="type">
        <el-tab-pane
          v-for="tab in areaTabs"
          :key="tab.value"
          :label="tab.name.toUpperCase()"
          :name="tab.value"
        />
      </el-tabs>
    </el-row>
    <div v-if="area.id">
      <attraction v-if="type === 'attraction'" :area-id="area.id" />
      <museum v-if="type === 'museum'" :area-id="area.id" />
      <event v-if="type === 'event'" :area-id="area.id" />
      <stay v-if="type === 'stay'" :area-id="area.id" />
      <cuisine v-if="type === 'cuisine'" :area-id="area.id" />
      <entertainment v-if="type === 'entertainment'" :area-id="area.id" />
      <company v-if="type === 'company'" :area-id="area.id" />
      <health v-if="type === 'health'" :area-id="area.id" />
      <real-estate v-if="type === 'real-estate'" :area-id="area.id" />
      <government-agency
        v-if="type === 'government-agency'"
        :area-id="area.id"
      />
      <financial v-if="type === 'financial'" :area-id="area.id" />
      <golf v-if="type === 'golf'" :area-id="area.id" />
      <restaurant v-if="type === 'restaurant'" :area-id="area.id" />
      <other v-if="type === 'other'" :area-id="area.id" />
      <tour v-if="type === 'tour'" :area-id="area.id" />
      <campaign v-if="type === 'campaign'" :area-id="area.id" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getPlaceById } from '@/services/place'
import { getPlaceTypes } from '@/utils/filters'

import Attraction from '../attraction/index'
import Museum from '../museum/index'
import Event from '../event/index'
import Stay from '../stay/index'
import Cuisine from '../cuisine/index'
import Entertainment from '../entertainment/index'
import Company from '../company/index'
import Health from '../health/index'
import RealEstate from '../real-estate/index'
import GovernmentAgency from '../government-agency/index'
import Financial from '../financial/index'
import Golf from '../golf/index'
import Restaurant from '../restaurant/index'
import Other from '../other/index'
import Tour from '../tour/index'
import Campaign from '../campaign/index'

export default {
  name: 'AreaDetail',
  components: {
    Attraction,
    Museum,
    Event,
    Stay,
    Cuisine,
    Entertainment,
    Company,
    Health,
    RealEstate,
    GovernmentAgency,
    Financial,
    Golf,
    Restaurant,
    Other,
    Tour,
    Campaign
  },
  data() {
    return {
      type: 'attraction',
      loading: true,
      area: { name: '' }
    }
  },
  computed: {
    ...mapGetters(['language', 'accountOrg']),
    areaTabs() {
      const tabs = getPlaceTypes().filter((t) => !['post'].includes(t.value))
      if (this.accountOrg) {
        const menus = this.accountOrg.menus.split(',')
        return tabs.filter((t) => menus.includes(t.value))
      }
      return tabs
    },
    params() {
      return {
        id: this.$route.params.id,
        locale: this.language
      }
    }
  },
  watch: {
    language: 'getDetailInfo'
  },
  beforeMount() {
    this.getDetailInfo()
  },
  methods: {
    getDetailInfo() {
      let request = null
      request = getPlaceById(this.params)
      this.loading = true
      request.then((response) => {
        this.area = response
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.area-detail {
  .title {
    padding: 15px;
    background-color: #2b314a;
    color: white;
  }
  .area-tabs {
    padding: 0 15px 15px 15px;
    background-color: #2b314a;
    color: white;
  }
}
</style>
<style lang="scss">
.area-detail {
  .area-tabs {
    .el-tabs__item {
      color: white;
      &:hover {
        color: #409eff;
      }
      &.is-active {
        color: #409eff;
      }
    }
  }
}
</style>
