<template>
  <div :class="type">
    <shared-index
      :columns="columns"
      :actions="actions"
      :type="type"
      :titles="titles"
      :header-actions="headerActions"
      :filterable="true"
      :area-id="areaId"
    />
  </div>
</template>

<script>
import SharedIndex from '@/components/shared/index'

export default {
  name: 'Health',
  components: {
    SharedIndex
  },
  props: {
    areaId: String
  },
  data() {
    return {
      type: 'health',
      titles: [{ label: 'CHĂM SÓC SỨC KHỎE', value: 'health' }],
      columns: ['stt', 'avatar', 'name', 'created_at', 'language', 'actions'],
      actions: ['showQR', 'edit', 'delete'],
      headerActions: ['add', 'filter', 'reset', 'synchronize']
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
