<template>
  <div :class="type">
    <shared-index
      ref="shared-parents"
      :columns="columns"
      :actions="actions"
      :type="type"
      :titles="titles"
      :header-actions="headerActions"
      :filterable="true"
      :area-id="areaId"
      @getChildrens="handleUpdateChildrenTitle"
      @reload="handleReloadAll"
    />

    <shared-index
      id="shared-childrens"
      ref="shared-childrens"
      :columns="columns"
      :actions="cActions"
      :type="type"
      :titles="cTitles"
      :header-actions="cHeaderActions"
      :filterable="true"
      :children="true"
      :parent-id="parentId"
      :area-id="areaId"
      @reload="handleReload"
    />
  </div>
</template>

<script>
import SharedIndex from '@/components/shared/index'

export default {
  name: 'Attraction',
  components: {
    SharedIndex
  },
  props: {
    areaId: String
  },
  data() {
    return {
      type: 'attraction',
      titles: [{ label: 'DANH THẮNG', value: 'attraction' }],
      cTitles: [{ label: 'DANH SÁCH DANH THẮNG CON', value: 'attractionChildren' }],
      columns: ['stt', 'avatar', 'ordered', 'name', 'created_at', 'language', 'actions'],
      actions: ['showQR', 'edit', 'delete', 'add', 'childrens'],
      cActions: ['copy', 'showQR', 'edit', 'delete'],
      headerActions: ['add', 'filter', 'reset', 'synchronize'],
      cHeaderActions: ['reset', 'synchronize'],
      parentId: ''
    }
  },
  methods: {
    handleUpdateChildrenTitle(attraction) {
      this.cTitles = [{ label: 'DANH SÁCH DANH THẮNG CON', value: 'attractionChildren' }]
      this.parentId = attraction.id
      this.cTitles = [{
        ...this.cTitles[0],
        label: `${this.cTitles[0].label} CỦA "${attraction.name.toUpperCase()}"`
      }]
      this.$nextTick(() => {
        const detail = document.getElementById('shared-childrens')
        detail.scrollIntoView({
          behavior: 'smooth'
        })
        this.$refs['shared-childrens'].handleGetChildrens()
      })
    },

    handleReload() {
      this.parentId = ''
      this.cTitles = [{ label: 'DANH SÁCH DANH THẮNG CON', value: 'attractionChildren' }]
    },

    handleReloadAll() {
      this.parentId = ''
      this.cTitles = [{ label: 'DANH SÁCH DANH THẮNG CON', value: 'attractionChildren' }]
      this.$nextTick(() => {
        this.$refs['shared-parents'].handleReloadDada()
        this.$refs['shared-childrens'].handleGetChildrens()
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
